:root {
  --version: 1.39;
}

body,
html {
  height: 100%;
  width: 100%;
}

body.fullscreen {
  overflow: clip;
}

body {
  overflow-x: clip;
  display: -webkit-box;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
          flex-direction: column;
}
body header,
body footer {
  -webkit-box-flex: 0;
          flex-grow: 0;
}
body main {
  -webkit-box-flex: 1;
          flex-grow: 1;
  display: -webkit-box;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
          flex-direction: column;
}
body main > *:first-child:last-child {
  -webkit-box-flex: 1;
          flex-grow: 1;
}
body main > *:last-child {
  padding-bottom: 1.25rem;
}
body.full-page main {
  position: relative;
}
body.full-page main [data-namespace=review] form {
  display: -webkit-box;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
          flex-direction: column;
  -webkit-box-pack: start;
          justify-content: flex-start;
  -webkit-box-align: start;
          align-items: flex-start;
  height: 100%;
}
@media (max-width: 767.98px) {
  body.full-page main > *:first-child {
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
  }
  body.full-page main div:not(.form-check):not(.autocomplete-suggestions) {
    height: 100%;
    width: 100%;
  }
  body.full-page main [data-namespace=review] form > div:not(.form-check):not(.autocomplete-suggestions) {
    height: auto;
  }
  body.full-page main > *:last-child {
    padding: 0 !important;
  }
}
body .sticky-top.col-extend-bg-right {
  overflow-x: hidden;
}
body .col-extend-bg-right {
  position: relative;
}
body .col-extend-bg-right:after {
  right: -999em;
  background: inherit;
  content: "";
  display: block;
  position: absolute;
  width: 999em;
  top: 0;
  bottom: 0;
  pointer-events: none;
  z-index: 0;
}
@media (max-width: 575.98px) {
  body .col-extend-bg-right:after {
    width: 1em;
    right: -1em;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) {
  body .col-extend-bg-right:after {
    width: 6em;
    right: -6em;
  }
}
body .col-extend-bg-left {
  position: relative;
}
body .col-extend-bg-left:before {
  left: -999em;
  background: inherit;
  content: "";
  display: block;
  position: absolute;
  width: 999em;
  top: 0;
  bottom: 0;
  pointer-events: none;
  z-index: 0;
}
@media (max-width: 575.98px) {
  body .col-extend-bg-left:before {
    width: 1em;
    left: -1em;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) {
  body .col-extend-bg-left:before {
    width: 6em;
    left: -6em;
  }
}
body:not(.gam_skin_ad_displayed) .container-ark {
  width: 100% !important;
  max-width: 100% !important;
  padding-right: 0 !important;
  padding-left: 0 !important;
  margin-right: 0 !important;
  margin-left: 0 !important;
}
body.gam_skin_ad_displayed .container-home {
  padding-right: 0 !important;
}
body.gam_skin_ad_displayed .container-ark {
  background: #f8f8fa !important;
  padding: 0 !important;
}
body.gam_skin_ad_displayed .gam_banner_my {
  display: none;
}
body.gam_skin_ad_displayed .col-extend-bg-left:before,
body.gam_skin_ad_displayed .col-extend-bg-right:after {
  display: none !important;
}

.border-white-opacity {
  border-color: rgba(255, 255, 255, 0.2) !important;
}

img.object-center {
  position: relative;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
}

.carousel-indicators li:not(.active) {
  background-color: #cdd6e0;
  opacity: 1 !important;
}

.modal {
  padding-right: 0 !important;
}

@media (max-width: 991.98px) {
  #home_search_bg {
    background: -webkit-gradient(linear, left top, left bottom, from(rgba(4, 35, 66, 0.7)), to(rgba(4, 35, 66, 0.1))) center;
    background: linear-gradient(180deg, rgba(4, 35, 66, 0.7) 0%, rgba(4, 35, 66, 0.1) 100%) center;
    background-size: cover;
  }
}
@media (max-width: 991.98px) {
  #insurance_lp_bg {
    background: -webkit-gradient(linear, left top, left bottom, from(rgba(4, 35, 66, 0.7)), to(rgba(4, 35, 66, 0.25))) center;
    background: linear-gradient(180deg, rgba(4, 35, 66, 0.7) 0%, rgba(4, 35, 66, 0.25) 100%) center;
    background-size: cover;
  }
}
.material-scrollbar {
  overscroll-behavior: contain;
}

.material-scrollbar::-webkit-scrollbar {
  height: 10px;
  overflow: visible;
  visibility: visible;
  width: 6px;
  background-clip: padding-box;
}

.material-scrollbar::-webkit-scrollbar-button {
  height: 0;
  width: 0;
}

.material-scrollbar::-webkit-scrollbar-thumb {
  background-color: #3e6a91;
  background-clip: padding-box;
  border: 0;
  min-height: 28px;
  border-radius: 15px;
}

.material-scrollbar::-webkit-scrollbar-track {
  background-clip: padding-box;
  border: 0;
  background-color: #e5e7ed;
  border-radius: 5px;
}

*.hidden-if-empty:empty {
  display: none;
}
